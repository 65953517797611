import Vue from "vue";

// Bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueLodash from "vue-lodash";
import "styles/common.scss"; // global css

import VueLazyload from "vue-lazyload";
import loading from "images/loading.svg";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import lodash from "lodash";
Vue.use(VueLodash, { name: "custom", lodash: lodash });

// Vue Masonry
import { VueMasonryPlugin } from "vue-masonry";
Vue.use(VueMasonryPlugin);

// Vue Debounce
import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

// Vue infinite scroll
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

//Zoomer
import VueZoomer from "vue-zoomer";
Vue.use(VueZoomer);
// import VuePackeryPlugin from 'vue-packery-plugin'
// Vue.use(VuePackeryPlugin)

// import {
//   Swiper as SwiperClass,
//   Pagination,
//   Navigation
// } from "swiper/swiper.esm";
// SwiperClass.use([Pagination, Navigation]);

// Swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// import "swiper/swiper-bundle.css";
Vue.use(VueAwesomeSwiper);

// i18n
import { i18n } from "./plugins/i18n";
import { Trans } from "./plugins/Translation";

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.mixin({
  data: function() {
    return {
      get apiUrl() {
        return process.env.VUE_APP_BASE_API_URL;
      },
      get s3Path() {
        // return "http://hkga-archive.s3-website.ap-east-1.amazonaws.com/";
        // return "https://hkga-archive.s3.ap-east-1.amazonaws.com/";
        return "https://hkga-archive-resized.s3.ap-east-1.amazonaws.com/";
      },
      get WP_API_URL() {
        // return "http://uat.graphicarchive.hk/api/";
        return "https://www.graphicarchive.hk/api/";
      }
    };
  }
});

Vue.filter("str_limit", function(value, size) {
  if (!value) return "";
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "...";
});

Vue.filter("imageResizedPath", function(value, size) {
  let imagePath = "";
  switch (size) {
    case "full":
      imagePath = value.replace(/(\.[\w\d?=_-]+)$/i, "-full$1");
      break;
    case "large":
      imagePath = value.replace(/(\.[\w\d?=_-]+)$/i, "-large$1");
      break;
    case "medium":
      imagePath = value.replace(/(\.[\w\d?=_-]+)$/i, "-medium$1");
      break;
    case "small":
      imagePath = value.replace(/(\.[\w\d?=_-]+)$/i, "-small$1");
      break;
    default:
      imagePath = value;
      break;
  }
  return imagePath;
});

// Vue.use(VueLazyload)
Vue.use(VueLazyload, {
  preLoad: 1,
  // error: 'dist/error.png',
  loading: loading,
  attempt: 1
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
