import store from "../store"; // your vuex store
import { Trans } from "@/plugins/Translation";

function load(component) {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/${component}.vue`);
}

const routes = [
  {
    path: "/:lang",
    props: true,
    component: {
      template: "<router-view></router-view>"
    },
    redirect: {
      name: "Home"
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: "/",
        name: "Home",
        component: load("Home/Index")
      },
      {
        path: "record/:slug",
        name: "Record Details",
        component: load("Record/Index")
      },
      {
        path: "collection/",
        name: "Collection",
        component: load("Collection/Index")
      },
      {
        path: "collection/:slug",
        name: "Collection Details",
        component: load("Collection/Details")
      },
      {
        path: "collectionEdit/:slug/:id",
        name: "Collection Edit",
        component: load("Collection/Edit")
      },
      {
        path: "about/",
        name: "About",
        component: load("About/Index")
      },
      {
        path: "events/",
        name: "Past Events",
        component: load("Events/Index")
      },      
      {
        path: "contact/",
        name: "Contact",
        component: load("Contact/Index")
      },
      {
        path: "login/",
        name: "Login",
        component: load("Login/Index")
      },
      {
        path: "profile/",
        name: "Profile",
        component: load("Account/Index")
      },
      {
        path: "reset/",
        name: "Reset Password",
        component: load("Login/resetIndex")
      },
      {
        path: "*",
        redirect: "/"
      }
    ]
  },

  // {
  //   path: "/exhibition",
  //   name: "Exhibition Index",
  //   component: load("Home/Index")
  // },
  // {
  //   path: "/exhibition/:slug",
  //   name: "Exhibition",
  //   component: load("Home/Index")
  // },
  // {
  //   path: "/collection/:slug",
  //   name: "Collection",
  //   component: load("Collection/Index")
  // },
  {
    path: "*",
    // redirect: "/"
    redirect(to) {
      return Trans.getUserSupportedLang() + "/";
    }
  }
];

export default routes;
