export default {
  home: {
    home_search_placeholder: "Search",
    home_back_to_top: "back to top",
    home_reserved:
      "Website © 2021 by Hong Kong Design Institute and Vocational Training Council. All rights reserved. ",
    home_result_of: "Result of",
    home_discover_more: "Discover More"
  },
  navbar: {
    navbar_discover: "Discover",
    navbar_about: "About",
    navbar_past_events: "Past Events",
    navbar_collection: "Collections",
    navbar_contact: "Contact",
    navbar_login: "Login",
    navbar_logout: "Logout",
    navbar_profile: "Profile"
  },
  about: {
    about_nav_about_us: "About us",
    about_nav_past_events: "Past Events",
    about_nav_acknowledgment: "Acknowledgment",
    about_nav_team: "Team",
    about_collaborators: "Collaborators",
    about_credit: "Credits",
    about_discover: "DISCOVER"
  },
  single: {
    single_media_type: "Media Type",
    single_primary_elements: "Primary elements",
    single_dimensions: "Dimensions",
    single_year_of_release: "Year Of Release",
    single_add_to_collection: "Add to my collection",
    single_you_also_like: "You may also like"
  },
  collection: {
    collection_title: "Collection",
    collection_back: "Back",
    collection_media_type: "Media Type",
    collection_primary_elements: "Primary elements",
    collection_dimensions: "Dimensions",
    collection_year_of_release: "Year Of Release",
    collection_gridview: "Grid View",
    collection_edit_button: "Edit",
    collection_remove_record: "Remove",
    collection_update_button: "UPDATE",
    collection_title_en: "Title (EN)",
    collection_description_en: "Description (EN)",
    collection_title_tc: "Title (TC)",
    collection_description_tc: "Description (TC)",
    collection_new_collecion: "Name new collection",
    collection_private_button: "Private",
    collection_create_collecion: "Create the collection",
    collection_new_collection_en_name: "English name required.",
    collection_new_collection_tc_name: "Chinese name required."
  },
  login: {
    login_title: "Login",
    login_button: "LOGIN",
    login_email: "Email",
    login_password: "Password",
    login_register: "Create account here",
    login_reset_password_title: "Reset password",
    login_reset_password_button: "RESET PASSWORD",
    login_reset_password_description:
      "We will send you an email to reset your password. ",
    login_error_invalid_email_pw: "Invalid Email or Password.",
    login_error_reset_email_not_found: "Email not found",
    login_error_reset_check_inbox: "Please check you email inbox!"
  },
  reset: {
    reset_new_password: "New Password",
    reset_description: "The password should have at least 8 characters.",
    reset_email: "Email",
    reset_password: "Password",
    reset_confirm_password: "Confirm Password",
    reset_reset_button: "RESET PASSWORD",
    reset_error_valid_email: "Please enter a valid email address.",
    reset_error_enter_new_pw: "Please enter a new password.",
    reset_password_not_match: "Password and confirm password does not match."
  },
  account: {
    account_title: "Account",
    account_name: "Name",
    account_email: "Email",
    account_password: "Password",
    account_edit_account: "Edit Account",
    account_my_collection: "My Collections",
    account_create_collection: "Create Collection",
    account_edit_collection: "Edit Collection",
    account_remove_collection: "Remove",
    account_first_name: "First Name",
    account_last_name: "Last Name",
    account_old_password: "Old Password",
    account_new_password: "New Password",
    account_confirm_new_password: "Confirm New Password",
    account_update_button: "UPDATE",
    account_old_pw_not_correct: "Old password not correct.",
    account_password_matching: "Password Must be Matching."
  },
  register: {
    register_email: "Email",
    register_password: "Password",
    register_confirm_password: "Confirm Password",
    register_register_password: "Register"
  }
};
