const state = {
  size: {
    mobile: false,
    tablet: false,
    desktop: false
  },
  screenSize: {
    width: 0,
    height: 0
  },
  loading: false,
  preventScrolling: false,
  user: null,
  token: null,
  allRecordWithTerm: [],
  singleItemHoverColor: ""
};

const mutations = {
  size(state, value) {
    switch (value) {
      case "mobile":
        state.size.mobile = true;
        state.size.tablet = false;
        state.size.desktop = false;
        break;
      case "tablet":
        state.size.mobile = false;
        state.size.tablet = true;
        state.size.desktop = false;
        break;
      case "desktop":
        state.size.mobile = false;
        state.size.tablet = false;
        state.size.desktop = true;
        break;
      default:
        break;
    }
  },
  setScreenSize(state, value) {
    state.screenSize = value;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setPreventScrolling(state, value) {
    state.preventScrolling = value;
  },

  setUser(state, value) {
    state.user = value;
  },

  setToken(state, value) {
    state.token = value;
  },
  setRecordwithTerm(state, value) {
    state.allRecordWithTerm = value;
  },
  setSingleItemHoverColor(state, value) {
    state.singleItemHoverColor = value;
  }
};

const actions = {
  checkWindowSize({ commit }, sizeValue) {
    if (sizeValue < 768) {
      commit("size", "mobile");
    } else if (sizeValue >= 768 && sizeValue < 992) {
      commit("size", "tablet");
    } else {
      commit("size", "desktop");
    }
  },

  setScreenSize({ commit }, value) {
    commit("setScreenSize", value);
  },

  setLoading({ commit }, value) {
    commit("setLoading", value);
  },

  setPreventScrolling({ commit }, value) {
    commit("setPreventScrolling", value);
  },

  setUser({ commit }, value) {
    commit("setUser", value);
  },

  setToken({ commit }, value) {
    commit("setToken", value);
  },

  setRecordwithTerm({ commit }, value) {
    commit("setRecordwithTerm", value);
  },

  setSingleItemHoverColor({ commit }, value) {
    commit("setSingleItemHoverColor", value);
  }
};

const getters = {
  getUser: state => {
    return state.user;
  },
  getToken: state => {
    return state.token;
  },
  getMobileSizeType: state => {
    return state.size;
  },
  isLoggedIn(state) {
    return state.user;
  },
  getRecordwithTerm(state) {
    return state.allRecordWithTerm;
  },
  getSingleItemHoverColor(state) {
    return state.singleItemHoverColor;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
