<template lang="html">
  <footer class="footer">
    <div class="footer--wrap inner-container">
      <div class="footer--group">
        <!-- <a href="#" class="footer--item">Privacy Policy</a>
        <a href="#" class="footer--item">Cookies Policy</a> -->
        <div class="backtotop" @click="backToTop">
          <img :src="backToTopIcon" alt="" />
          <span>TOP</span>
        </div>
      </div>
      <div class="footer--copyright">
        <p>© 2022 All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
import backToTopIcon from "@/assets/images/icon-backtotop.svg";

export default {
  name: "PageFooter",
  data() {
    return {
      backToTopIcon: backToTopIcon
    };
  },
  methods: {
    backToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid $color-black;
  height: 90px;
  position: relative;
  overflow: hidden;
  display: flex;
  @include respond-to(screen-sm) {
    height: 96px;
  }
  &--wrap {
    margin: auto;
    // display: flex;
    width: 100%;
    justify-content: space-between;
    display: block;
    @include respond-to(screen-sm) {
      display: flex;
    }
  }
  &--group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include respond-to(screen-sm) {
      margin-bottom: 0px;
    }
  }
  &--item,
  &--copyright {
    @include typo(plbl);
  }
  &--item {
    margin-right: 40px;
    @include transition(all 300ms ease-in-out);
    &:hover {
      opacity: 0.6;
    }
  }
}

.backtotop {
  cursor: pointer;
  display: flex;
  margin-left: auto;
  align-items: center;
  @include fontfamily(HelConBold);
  @include respond-to(screen-sm) {
    display: none;
  }
  span {
    margin-left: 5px;
    font-size: $fs-xs;
  }
}
</style>
