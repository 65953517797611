<template>
  <div id="app">
    <div class="global-container">
      <navBar />
      <transition name="page" mode="out-in">
        <router-view :key="$route.fullPath" />
      </transition>
    </div>
    <page-footer />
  </div>
</template>

<script>
import { authHeader, removeAuthData } from "@/helpers/authHeader";
import { mapState, mapActions, mapMutations } from "vuex";
import NavBar from "@/components/common/NavBar";
import PageFooter from "@/components/common/PageFooter";

export default {
  name: "App",
  components: { NavBar, PageFooter },
  computed: {
    ...mapState({
      loading: state => state.common.loading,
      preventScrolling: state => state.common.preventScrolling
    })
  },
  data() {
    return {
      resizeTimeout: null
    };
  },

  beforeCreate() {
    this.$nextTick(() => {
      this.checkWindowSize(window.innerWidth);
      this.setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    });
  },

  beforeMount() {
    if (!this.lodash.isEmpty(authHeader())) {
      this.getUserAPI();
    }

    this.apiGetSingleItemHoverColor();
    this.getAllRecordWithTerm();
  },

  created() {
    // console.log(this);
    window.addEventListener("resize", this.resizeHandler);
  },

  methods: {
    ...mapActions("common", [
      "checkWindowSize",
      "setScreenSize",
      "setUser",
      "setToken",
      "setRecordwithTerm",
      "setSingleItemHoverColor"
    ]),

    resizeHandler() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.checkWindowSize(window.innerWidth);
        this.setScreenSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }, 100);
    },

    async apiGetSingleItemHoverColor() {
      const response = await fetch(
        // `http://uat.graphicarchive.hk/api/main-home`,
        `${this.WP_API_URL}main-home`,
        {
          method: "GET",
          mode: "cors",
          cache: "default"
        }
      );
      const data = await response.json();

      const recordData = data.module[0];

      this.homeRecord = recordData.content;
      this.setSingleItemHoverColor(recordData.content.hoverColor);
    },

    async getUserAPI() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/getUser`,
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            headers: authHeader()
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }
        const data = await response.json();
        this.setUser(data);
      } catch (error) {
        removeAuthData();
      }
    },

    async getAllRecordWithTerm() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/term`,
          {
            method: "GET",
            mode: "cors",
            cache: "default"
          }
        );

        if (!response.ok) {
          throw new Error("API Error");
        }

        const data = await response.json();
        this.setRecordwithTerm(data);
      } catch (error) {
        console.log(error);
      }
    }
  },

  watch: {
    preventScrolling: {
      handler: function(val, oldVal) {
        if (val) {
          document.documentElement.style.overflow = "hidden";
          return;
        }

        document.documentElement.style.overflow = "auto";
      },
      deep: true
    }
  }
};
</script>

<style lang="scss"></style>
