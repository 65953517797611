<template lang="html">
  <div>
    <div class="nav">
      <div class="inner-container">
        <!-- desktop-nav start-->
        <div class="desktop-nav">
          <div class="logo-wrap">
            <router-link :to="'/' + lang + '/'"  >
              <img :src="navLogo" alt="" />
            </router-link>
          </div>
          <div class="nav--wrap">
            <div class="lang-wrap">
              <a href="" 
              v-for="(item, i) in langList"
              :key="i"
              v-if="item.slug != $route.params.lang"
              @click.prevent="changeLanguage(item.slug)"
              class="nav--item nav--lang">{{ item.label }}</a>
            </div>
            <div class="item-wrap">
              <router-link :to="'/' + lang + '/'" class="nav--item" @click.native="toggleMobileNav">
                  {{ $t("navbar.navbar_discover") }} 
              </router-link>
              <router-link :to="'/' + lang + '/about'" class="nav--item" @click.native="toggleMobileNav">
                  {{ $t("navbar.navbar_about") }} 
              </router-link>
              <router-link :to="'/' + lang + '/events'" class="nav--item" @click.native="toggleMobileNav">
                  {{ $t("navbar.navbar_past_events") }} 
              </router-link>
              <router-link :to="'/' + lang + '/collection'" class="nav--item" @click.native="toggleMobileNav">
                  {{ $t("navbar.navbar_collection") }} 
              </router-link>
              <router-link :to="'/' + lang + '/contact'" class="nav--item" @click.native="toggleMobileNav">
                  {{ $t("navbar.navbar_contact") }} 
              </router-link>
              <router-link v-if="!isLoggedIn" :to="'/' + lang + '/login'" class="nav--item" @click.native="toggleMobileNav">
                  {{ $t("navbar.navbar_login") }} 
              </router-link>
              <router-link v-if="isLoggedIn" :to="'/' + lang + '/profile'" class="nav--item" @click.native="toggleMobileNav">
                  {{ $t("navbar.navbar_profile") }} 
              </router-link>
               <a href="#" @click.prevent="logoutUser" v-if="isLoggedIn" class="nav--item">{{ $t("navbar.navbar_logout") }}</a>
            </div>
          </div>
        </div>
        <!-- end of desktop-nav-->
        <!-- mobile-nav stat -->
        <div class="m-nav">
          <div class="logo-wrap">
              <router-link :to="'/' + lang + '/'">
                <img :src="navLogo" alt="" />
               </router-link>
            </a>
          </div>
          <div class="item-wrap">
            <a href="#" v-if="isLoggedIn" class="nav--item">Login</a>
            <a href="#" @click.prevent="logoutUser" v-if="isLoggedIn" class="nav--item">{{ $t("navbar.navbar_logout") }}</a>
            <div
              class="toggle-wrap"
              :class="{ active: mobileNav}"
              @click="toggleMobileNav"
            >
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="m-nav--main" :class="{ opened: mobileNav }">
      <div class="m-nav--list">
        <router-link :to="'/' + lang + '/'" class="nav--item" @click.native="toggleMobileNav">
            {{ $t("navbar.navbar_discover") }} 
        </router-link>
        <router-link :to="'/' + lang + '/about'" class="nav--item" @click.native="toggleMobileNav">
            {{ $t("navbar.navbar_about") }} 
        </router-link>
        <router-link :to="'/' + lang + '/events'" class="nav--item" @click.native="toggleMobileNav">
            {{ $t("navbar.navbar_past_events") }} 
        </router-link>
        <router-link :to="'/' + lang + '/collection'" class="nav--item"  @click.native="toggleMobileNav">
            {{ $t("navbar.navbar_collection") }} 
        </router-link>
        <router-link :to="'/' + lang + '/contact'" class="nav--item"  @click.native="toggleMobileNav">
            {{ $t("navbar.navbar_contact") }} 
        </router-link>
        <router-link v-if="!isLoggedIn" :to="'/' + lang + '/login'" class="nav--item"  @click.native="toggleMobileNav">
            {{ $t("navbar.navbar_login") }} 
        </router-link>
        <router-link v-if="isLoggedIn" :to="'/' + lang + '/profile'" class="nav--item"  @click.native="toggleMobileNav">
            {{ $t("navbar.navbar_profile") }} 
        </router-link>
        <a href="" 
        v-for="(item, i) in langList"
        :key="i"
        v-if="item.slug != $route.params.lang"
        @click.prevent="changeLanguage(item.slug)"
        class="nav--item nav--lang" >{{ item.label }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { Trans } from "@/plugins/Translation";
import {  removeAuthData } from "@/helpers/authHeader";
import navLogo from "@/assets/images/main-logo_v2.svg";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "NavBar",
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),
      navLogo: navLogo,
      mobileNav: false,
      langList: [
        {
          label: "EN",
          slug: "en"
        },
        {
          label: "中",
          slug: "tc"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("common", ["isLoggedIn"])
  },
  methods: {
    ...mapActions("common", ["setUser", "setToken"]),
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    logoutUser(){
      removeAuthData();
      this.setUser(null);
      this.setToken(null);
      this.$router.push("/login");
    },
    changeLanguage(lang) {

      console.log(lang);
      if (lang == this.$route.params.lang) {
        return;
      }
      const to = this.$router.resolve({ params: { lang } });
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.nav {
  position: relative;
  padding: 20px 0;
  height: 80px;
  border-bottom: 1px solid $color-black;
  z-index: 101;
  @include respond-to(screen-sm) {
    padding: 35px 0;
    height: auto;
  }
  .inner-container {
    width: 100%;
  }
  .logo-wrap {
    > * {
      position: relative;
      display: block;
    }
    img {
      width: 180px;
      height: auto;
      @include respond-to(screen-sm) {
        width: 320px;
        height: auto;
      }
    }
  }
  &--wrap {
    margin: auto 0;
  }
  .item-wrap {
    display: flex;
  }
  &--item {
    display: block;
    @include typo(standardlbl);
    @include transition(all 200ms ease-in-out);
    opacity: 1;
    &:hover {
      opacity: 0.6;
    }
    margin: auto;
    @include respond-to(screen-sm) {
      margin-left: 30px;
      display: flex;
    }
  }
  &--lang {
    font-size: $fs-s;
    text-align: left;
    margin-bottom: 0;
    padding-top: 10px;
    display: block;
    margin-bottom: 0;
    @include respond-to(screen-sm) {
      text-align: right;
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 10px;
    }
  }
}

.desktop-nav {
  justify-content: space-between;
  display: none;
  @include respond-to(screen-sm) {
    display: flex;
  }
}
.m-nav {
  display: flex;
  justify-content: space-between;
  @include respond-to(screen-sm) {
    display: none;
  }
  .toggle-wrap {
    margin: auto 0 auto 20px;
    cursor: pointer;
    span {
      width: 30px;
      height: 1px;
      margin: 7px 0;
      background-color: $color-black;
      display: block;
      @include transition(all 300ms $easeInOutCubic);
    }
    &.active {
      span:first-child {
        @include transform(rotate(-45deg) translate(-3px, 2px));
      }
      span:last-child {
        @include transform(rotate(45deg) translate(-3px, -2px));
      }
    }
  }
  .item-wrap {
    display: flex;
  }
  &--main {
    background-color: #e6e6e6;
    // margin-top: -10px;
    z-index: 98;
    // border-top: 1px solid $color-black;
    margin-top: -1px;
    display: block;
    position: absolute;
    overflow: hidden;
    z-index: 2;
    top: 80px;
    width: 100%;
    border-bottom: 1px solid $color-black;
    height: 0;
    @include transition(all 300ms $easeInOutCubic);
    @include respond-to(screen-sm) {
      display: none;
    }
    &.opened {
      // height: 185px;
      height: auto;
    }
  }
  &--list {
    padding: 20px 20px 0;
    .nav--item {
      margin-bottom: 10px;
    }
  }
}
</style>
