import Cookies from "js-cookie";
const LangKey = "HKGA-MAINSITE-LANGUAGE";

export const DEFAULT_LANGUAGE = Cookies.get(LangKey) || "en";
export const FALLBACK_LANGUAGE = Cookies.get(LangKey) || "en";
export const SUPPORTED_LANGUAGES = ["en", "tc"];

export function setLang(lang) {
  return Cookies.set(LangKey, lang);
}
